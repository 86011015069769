import React, { useEffect, useState, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { connect } from 'react-redux';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { Loader, enumPolicies } from '../../../_metronic/helpers'
import { Button, Row, Col, CardBody, Card, CardFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import Moment from 'react-moment'
import moment from 'moment';
import { Audit } from '../../../_metronic/helpers/components/Audit';
import { Modal } from 'react-bootstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation';
import PolicyChecker from '../../../_metronic/helpers/components/PolicyChecker'
import InvoicePrint from './invoicePrint';
import Flatpickr from 'react-flatpickr'
import "flatpickr/dist/themes/light.css";

import { clearState, getInvoice, patchInvoice, putItems, postTransaction, getHistory } from './invoiceActions';


const Invoice = (props) => {
    //inputs from url
    const param = useParams();
    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();

    const fpinvoicedate = useRef(null);
    const fpexpirydate = useRef(null);
    const fppaymentdate = useRef(null);

    const [items, setItems] = useState([])
    const [invoiceForm, setInvoiceForm] = useState()
    const [transactionForm, setTransactionForm] = useState()
    const [itemsForm, setItemsForm] = useState()
    const [invoiceDate, setInvoiceDate] = useState(null);
    const [expiryDate, setExpiryDate] = useState(null);
    const [paymentDate, setPaymentDate] = useState();
    const [amounts, setAmounts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [comment, setComment] = useState('');
    const [customContact, setCustomContact] = useState('');
    const [transactionType, setTransactionType] = useState(1);

    const tabsModel = [
        { id: 1, name: intl.formatMessage({ id: 'INVOICE.INVOICE' }), active: " active", hash: "#invoice_tab_1", show: 'show', policies: [enumPolicies.InvoiceRead, enumPolicies.InvoiceWrite, enumPolicies.InvoiceEdit] },
        { id: 2, name: intl.formatMessage({ id: 'INVOICE.SUMMARY' }), active: " ", hash: "#invoice_tab_2", show: '', policies: [enumPolicies.InvoiceWrite, enumPolicies.InvoiceEdit] },
        { id: 3, name: intl.formatMessage({ id: 'INVOICE.HISTORY' }), active: " ", hash: "#invoice_tab_3", show: '', policies: [enumPolicies.InvoiceWrite, enumPolicies.InvoiceEdit] }
    ]

    const [tabs, setTabs] = useState(tabsModel);


    //inputs from redux
    const {
        invoice,
        historyList,
        success,
        loading,
        historyLoading,
    } = props;

    //actions
    const {
        clearState,
        getInvoice,
        patchInvoice,
        putItems,
        postTransaction,
        getHistory

    } = props;

    useEffect(() => {
        getInvoice(param.id)
        getHistory(param.id)
        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success])

    useEffect(() => {
        if (invoice) {
            setCustomContact(invoice.customContact)
        }

        if (invoice && invoice.invoiceItems) {
            setItems(invoice.invoiceItems);
            setExpiryDate(invoice.expiryDate);
            setInvoiceDate(invoice.invoiceDate);
        }
    }, [invoice])

    useEffect(() => {
        if (invoice && invoice.invoiceItems) {
            setAmounts(invoice.invoiceItems)
        }
    }, [items])

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tabParam = queryParams.get('tab'); // Query string parametresini alın

        if (tabParam) {
            const updatedTabs = tabsModel.map(tab => ({
                ...tab,
                active: tab.id === parseInt(tabParam) ? "active" : "",
                show: tab.id === parseInt(tabParam) ? "show" : ""
            }));
            setTabs(updatedTabs);
        }
    }, [location.search, param.id]);

    const usersBreadcrumbs = [
        {
            title: intl.formatMessage({ id: 'INVOICES.BREADCRUMB.TITLE' }),
            path: '/order-management/invoices',
            isSeparator: false,
            isActive: false,
        },
        {
            isSeparator: true,
            isActive: false,
        }
    ]

    const validation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        }
    }

    const onPrintContent = () => {

        if (invoice && invoice.invoiceItems && invoice.invoiceItems.length > 0) {
            const markup = renderToStaticMarkup(
                <InvoicePrint invoice={invoice} intl={intl} />
            );

            const newTab = window.open('', '_blank');
            newTab.document.write(markup);
            newTab.print();
        }
    }

    const onInvoiceDateChange = ([date]) => {
        var x = moment(date).format('YYYY-MM-DD');
        setInvoiceDate(x);
    }

    const onExpiryDateChange = ([date]) => {
        var x = moment(date).format('YYYY-MM-DD');
        setExpiryDate(x);
    }

    const onPaymentDateChange = ([date]) => {
        var x = moment(date).format('YYYY-MM-DD');
        setPaymentDate(x);
    }

    const handleInvoiceValidSubmit = (event, values) => {
        patchInvoice(param.id, {
            invoiceDate: {
                set: true,
                value: invoiceDate
            },
            expiryDate: {
                set: true,
                value: expiryDate
            },
            orderId: {
                set: true,
                value: values.orderId
            },
            customerCompany: {
                set: true,
                value: values.customerCompany
            },
            reference: {
                set: true,
                value: values.reference
            },
            customContact: {
                set: true,
                value: values.customContact
            }
        })
    }

    const handleItemsValidSubmit = (event, values) => {
        if (values && values.items)
            putItems(param.id, values.items.filter(x => x != null))
        else
            putItems(param.id, [])
    }

    const onItemRemove = (index) => {
        Swal.fire({
            title: intl.formatMessage({ id: 'INVOICE.DELETE_ITEM' }),
            text: intl.formatMessage({ id: 'INVOICE.DELETE_ITEM_CONFIRM' }),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({ id: 'GENERAL.YES' }),
            cancelButtonText: intl.formatMessage({ id: 'GENERAL.NO' }),
        }).then((result) => {
            if (result.isConfirmed) {
                var xItems = [...items];
                xItems[index] = { ...xItems[index], show: false };
                setItems(xItems)
            }
        })
    }

    const onItemAdd = () => {
        var newItem = {
            id: "0",
            description: '',
            unitPrice: 0,
            totalPrice: 0,
            vat: 0,
            show: true
        }
        var xItems = [...items, newItem];
        setItems(xItems)
    }

    const onInputChange = (index, value, type) => {
        var xAmounts = [...items];
        for (let i = 0; i < xAmounts.length; i++) {

            if (xAmounts[i].show == false) {
                xAmounts[i].quantity = 0;
                xAmounts[i].unitPrice = 0;
                xAmounts[i].totalPrice = 0;
                continue;
            }

            if (i == index) {
                if (type == 1) {
                    xAmounts[i].quantity = value;
                    xAmounts[i].totalPrice = xAmounts[i].unitPrice * value;
                }
                if (type == 2) {
                    xAmounts[i].unitPrice = value;
                    xAmounts[i].totalPrice = xAmounts[i].quantity * value;
                }
                if (type == 3) {
                    xAmounts[i].vat = value;
                }
            }
        }
        setItems(xAmounts);
    }

    const getTotals = (type) => {
        var total = 0;
        if (type == 1) {
            items.filter(x => x.show != false).map((item) => {
                total += item.totalPrice;
            })
        }
        if (type == 2) {
            items.filter(x => x.show != false).map((item) => {
                total += item.totalPrice * item.vat / 100;
            })
        }
        if (type == 3) {
            items.filter(x => x.show != false).map((item) => {
                total += item.totalPrice + (item.totalPrice * item.vat / 100);
            })
        }
        return total.toFixed(2);
    }


    const onPaymentClick = () => {
        setShowModal(true);
    }

    const handleTransactionValidSubmit = (event, values) => {
        postTransaction(param.id, {
            transactionAmount: values.transactionAmount,
            transactionDate: paymentDate
        })
        setShowModal(false);
        transactionForm.reset();
    }

    const addComment = () => {
        if (comment == '') return;
        patchInvoice(param.id, {
            note: {
                set: true,
                value: comment
            }
        })
        setComment('')
    }

    const handleDescriptionChange = (index, e) => {
        var xItems = [...items];
        xItems[index].description = e.target.value;
        setItems(xItems);
    }

    const handleCustomContactChange = (e) => {
        setCustomContact(e.target.value);
    }

    const getRows = (text) => {
        if (text)
            return text.split('\n').length + 1;

        return 2;
    }

    const onTransactionTypeChange = (type) => {
        setTransactionType(type);
    }

    const renderTransactions = () => {
        if (invoice && invoice.paidAmount > 0 && invoice.paidAmount < getTotals(3)) {

            return (
                <>

                    <tr className='fs-4 gs-0'>
                        <td colSpan={1}></td>
                        <td colSpan={2} >
                            {intl.formatMessage({ id: 'INVOICE.ALREADY_PAID' })}:
                        </td>
                        <td >
                            € {invoice.paidAmount}
                        </td>
                        <td colSpan={1}>
                        </td>
                    </tr>
                    <tr className='fs-4 gs-0'>
                        <td colSpan={1}></td>
                        <td colSpan={2} >
                            {intl.formatMessage({ id: 'INVOICE.OUTSTANDING' })}:
                        </td>
                        <td >
                            € {getTotals(3) - invoice.paidAmount}
                        </td>
                        <td colSpan={1}>
                        </td>
                    </tr>

                </>
            )
        }
    }

    const getColors = (status, name) => {
        switch (status) {
            case 1:
                return <span className="badge badge-light-warning">{name}</span>
            case 2:
                return <span className="badge badge-light-success">{name}</span>
            case 3:
                return <span className="badge badge-light-danger">{name}</span>
            case 4:
                return <span className="badge badge-light-dark">{name}</span>
            default:
                return <span className="badge badge-light-primary">{name}</span>
        }
    }

    const renderSummary = () => {

        var tab = tabs.find(x => x.id == 2);

        return (
            <div className={`tab-pane fade ${tab.active} ${tab.show}`} id={tab.hash.replace('#', '')} role="tab-panel">
                <div className="d-flex flex-column gap-7 gap-lg-10">
                    <div className="card ">
                        <div className="card-header">
                            <div className="card-title">
                                <h2>{intl.formatMessage({ id: "INVOICE.INVOICE" })} - (#{invoice && invoice.invoiceNr}) {invoice && getColors(invoice.invoiceState, invoice.invoiceStateName)}</h2>
                            </div>
                            <div className="card-toolbar">
                                <div className="d-flex flex-end rounded-3 mt-5">
                                    <i className={`ki-duotone ki-printer fs-3x mr-5`} onClick={() => onPrintContent()}>
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        <span className="path4"></span>
                                        <span className="path5"></span>
                                    </i>
                                    <i className={`ki-duotone ki-euro fs-3x`} onClick={() => onPaymentClick()}>
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <AvForm key={1} onValidSubmit={handleInvoiceValidSubmit} ref={(e) => (setInvoiceForm(e))}>
                                <Row>
                                    <Col>
                                        <AvField name="invoiceNr" label={intl.formatMessage({ id: 'INVOICE.FIELD.NUMBER' })} type="text" value={invoice && invoice.invoiceNr} readOnly={true} className="form-control-solid" />
                                    </Col>
                                    <Col >
                                        <AvField name="customerCompany" label={intl.formatMessage({ id: 'INVOICE.FIELD.CUSTOMERCOMPANY' })} type="text" value={invoice && invoice.customerCompany} validate={validation} />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <div className="mb-3">
                                            <label htmlFor="invoiceDate" className="form-label">{intl.formatMessage({ id: 'INVOICE.FIELD.INVOICE_DATE' })}</label>
                                            <Flatpickr ref={fpinvoicedate}
                                                onChange={onInvoiceDateChange}
                                                options={{
                                                    dateFormat: "d-m-Y",
                                                }}
                                                value={invoice && moment(invoice.invoiceDate).format('DD-MM-YYYY')}
                                                className='form-control'
                                                placeholder='Select Invoice Date'
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="mb-3">
                                            <label htmlFor="expiryDate" className="form-label">{intl.formatMessage({ id: 'INVOICE.FIELD.EXPIRY_DATE' })}</label>
                                            <Flatpickr ref={fpexpirydate}
                                                onChange={onExpiryDateChange}
                                                options={{
                                                    dateFormat: "d-m-Y",
                                                }}
                                                value={invoice && moment(invoice.expiryDate).format('DD-MM-YYYY')}
                                                className='form-control'
                                                placeholder='Select Expiry Date'
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <AvField name="customContact" label={intl.formatMessage({ id: 'INVOICE.FIELD.CUSTOMER' })} type="textarea" rows={getRows(customContact)} value={invoice && invoice.customContact} validate={validation} onChange={(e) => handleCustomContactChange(e)} />
                                    </Col>
                                    <Col>
                                        <AvField name="reference" label={intl.formatMessage({ id: 'INVOICE.FIELD.REFERENCE' })} type="text" value={invoice && invoice.reference} validate={validation} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='text-end'>
                                        <AvField type="hidden" name="id" value={invoice && invoice.id} />
                                        <Button color="success" type="submit">{intl.formatMessage({ id: 'INVOICE.SAVE' })}</Button>
                                    </Col>
                                </Row>
                            </AvForm>
                        </div>
                    </div>
                </div>
                {renderItems()}

            </div>
        )
    }

    const renderItems = () => {
        return (
            <div className="card mt-10">
                <div className="card-header">
                    <div className="card-title">
                        <h2>{intl.formatMessage({ id: "INVOICE.ITEMS" })}</h2>
                    </div>
                </div>
                <div className="card-body">
                    <AvForm key={1} onValidSubmit={handleItemsValidSubmit} ref={(e) => (setItemsForm(e))}>
                        <div className='table-responsive'>
                            <table className="table align-middle table-row-dashed" style={{ minWidth: "800px" }}>
                                <thead>
                                    <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                        <th className="w-400px">{intl.formatMessage({ id: "INVOICE.FIELD.DESCRIPTION" })}</th>
                                        <th className="w-75px">{intl.formatMessage({ id: "INVOICE.FIELD.QUANTITY" })}</th>
                                        <th className="w-100px">{intl.formatMessage({ id: "INVOICE.FIELD.PRICE" })}</th>
                                        <th className="w-100px">{intl.formatMessage({ id: "INVOICE.FIELD.AMOUNT" })}</th>
                                        <th className="w-75px">{intl.formatMessage({ id: "INVOICE.FIELD.VAT" })}</th>
                                        <th className="w-75px">{intl.formatMessage({ id: "INVOICE.ACTIONS" })}</th>
                                    </tr>
                                </thead>
                                <tbody className="fw-semibold text-gray-600">

                                    {items && items.map((item, index) => {
                                        if (item.show == false) {
                                            return null;
                                        }
                                        return (
                                            <tr key={`invoce_item_${index}`} >
                                                <td>
                                                    <AvField type="hidden" name={`items[${index}].id`} value={item.id} />
                                                    <AvField name={`items[${index}].description`} rows={getRows(item.description)} type="textarea" value={item.description} validate={validation} onChange={(e) => handleDescriptionChange(index, e)} />
                                                </td>
                                                <td>
                                                    <AvField name={`items[${index}].quantity`} type="number" value={item.quantity} validate={validation} className="hide-arrow" onChange={(e) => onInputChange(index, e.target.value, 1)} />
                                                </td>
                                                <td>
                                                    <AvField name={`items[${index}].unitPrice`} type="number" value={item.unitPrice} validate={validation} className="hide-arrow" onChange={(e) => onInputChange(index, e.target.value, 2)} />
                                                </td>
                                                <td>
                                                    <AvField name={`items[${index}].totalPrice`} type="number" value={item.totalPrice} validate={validation} readOnly={true} className="form-control-solid" />
                                                </td>
                                                <td>
                                                    <AvField name={`items[${index}].vat`} type="number" value={item.vat} validate={validation} className="hide-arrow" onChange={(e) => onInputChange(index, e.target.value, 3)} />
                                                </td>
                                                <td>
                                                    <Button className='mb-4' color="danger" onClick={() => { onItemRemove(index) }}><i className="bi bi-trash"></i></Button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td colSpan={5}>
                                        </td>
                                        <td>
                                            <Button color="warning" onClick={() => { onItemAdd() }}><i className="bi bi-plus"></i></Button>
                                        </td>
                                    </tr>

                                    <tr className='fs-4 gs-0'>
                                        <td colSpan={1}></td>
                                        <td colSpan={2}>
                                            {intl.formatMessage({ id: 'INVOICE.TOTAL_EXCLUDING_VAT' })}:
                                        </td>
                                        <td >
                                            € {getTotals(1)}
                                        </td>
                                        <td colSpan={1}>
                                        </td>
                                    </tr>
                                    <tr className='fs-4 gs-0'>
                                        <td colSpan={1}></td>
                                        <td colSpan={2}>
                                            {intl.formatMessage({ id: 'INVOICE.TOTAL_VAT' })}:
                                        </td>
                                        <td >
                                            € {getTotals(2)}
                                        </td>
                                        <td colSpan={1}>
                                        </td>
                                    </tr>
                                    <tr className='text-gray-600 fs-2 gs-0'>
                                        <td colSpan={1}></td>
                                        <td colSpan={2} >
                                            {intl.formatMessage({ id: 'INVOICE.TOTAL_INCLUDING_VAT' })}:
                                        </td>
                                        <td >
                                            € {getTotals(3)}
                                        </td>
                                        <td colSpan={1}>
                                        </td>
                                    </tr>
                                    {renderTransactions()}
                                </tbody>
                            </table>
                        </div>

                        <Row className='mt-10'>
                            <Col>
                            </Col>
                            <Col className='text-end'>
                                <Button color="success" type="submit">{intl.formatMessage({ id: 'INVOICE.SAVE' })}</Button>
                            </Col>
                        </Row>
                        <AvField type="hidden" name="id" value={invoice && invoice.id} />
                    </AvForm>



                </div>
            </div>
        )
    }

    const renderOrderIcon = () => {
        if (invoice?.orderId > 0) {
            return (
                <>
                <i className={`ki-duotone ki-delivery-2 fs-3x mr-5`} onClick={() => navigate(`/order-management/orders/${invoice.orderId}`)}>
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path7"></span>
                    <span className="path8"></span>
                    <span className="path9"></span>
                </i>
                </>
            );
        }
        
        return null;
    }

    const renderInvoice = () => {
        var tab = tabs.find(x => x.id == 1);

        return (
            <div className={`tab-pane fade ${tab.active} ${tab.show}`} id={tab.hash.replace('#', '')} role="tab-panel">
                <div className="d-flex flex-column gap-7 gap-lg-10">
                    <div className="card ">
                        <div className="card-header">
                            <div className="card-title">
                                <h2>{intl.formatMessage({ id: "INVOICE.INVOICE" })} - (#{invoice && invoice.invoiceNr}) {invoice && getColors(invoice.invoiceState, invoice.invoiceStateName)}</h2>
                            </div>
                            <div className="card-toolbar">
                                <div className="d-flex flex-end rounded-3 mt-5">
                                    {renderOrderIcon()}
                                    <i className={`ki-duotone ki-printer fs-3x mr-5`} onClick={() => onPrintContent()}>
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        <span className="path4"></span>
                                        <span className="path5"></span>
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="card-body" style={{ overflowY: "auto" }}>
                            <InvoicePrint invoice={invoice} component={true} intl={intl}></InvoicePrint>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderHistory = () => {
        var tab = tabs.find(x => x.id == 3);

        return (
            <div className={`tab-pane fade ${tab.active} ${tab.show}`} id={tab.hash.replace('#', '')} role="tab-panel">
                <div className="d-flex flex-column gap-7 gap-lg-10">
                    <div className="card card-flush py-4 flex-row-fluid">
                        <div className="card-header">
                            <div className="card-title">
                                <h2>{intl.formatMessage({ id: "ORDER.FIELD.HISTORY" })} {invoice && getColors(invoice.invoiceState, invoice.invoiceStateName)}</h2>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <div className="table-responsive">
                                <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                            <th className="min-w-100px">{intl.formatMessage({ id: "ORDER.FIELD.USER" })}</th>
                                            <th className="min-w-175px">{intl.formatMessage({ id: "ORDER.FIELD.COMMENT" })}</th>
                                            <th className="min-w-100px">{intl.formatMessage({ id: "ORDER.FIELD.DATE" })}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-600">
                                        {historyList && historyList.map((history, index) => {
                                            return (
                                                <tr key={`history_row_${index}`}>
                                                    <td>{history.userName}</td>
                                                    <td>{history.fieldName}</td>
                                                    <td><Moment>{history.date}</Moment></td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <CardFooter>
                            <Row>
                                <Col md={11}>
                                    <textarea rows={2} className='form-control' onChange={(e) => setComment(e.target.value)} value={comment} placeholder={intl.formatMessage({ id: "INVOICE.HISTORY.ADD_NOTE" })}></textarea>
                                </Col>
                                <Col md={1}>
                                    <button className="btn btn-primary" onClick={() => addComment()} style={{ width: "100%", height: "100%" }}>
                                        <i className="ki-duotone ki-send fs-2x"><span className="path1"></span><span className="path2"></span></i>
                                    </button>
                                </Col>
                            </Row>
                            {historyLoading && <Loader />}
                        </CardFooter>
                    </div>
                </div>
            </div>
        )
    }

    const renderModal = () => {

        return (
            <Modal centered show={showModal} onHide={() => setShowModal(!showModal)}>
                <Modal.Header closeButton>
                    <Modal.Title>{intl.formatMessage({ id: 'INVOICE.MODAL.PROCESS_PAYMENT' })}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="py-5 mb-5">
                        <Row>
                            <Col>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" value="1" id="transactionType1" name="transactionType" checked={transactionType == 1} onChange={() => onTransactionTypeChange(1)} />
                                    <label class="form-check-label" for="transactionType1">
                                        {intl.formatMessage({ id: 'INVOICE.MODAL.FULL_PAYMENT' })}
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" value="2" id="transactionType2" name="transactionType" checked={transactionType == 2} onChange={() => onTransactionTypeChange(2)} />
                                    <label class="form-check-label" for="transactionType2">
                                        {intl.formatMessage({ id: 'INVOICE.MODAL.PARTIAL_PAYMENT' })}
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <AvForm key={1} onValidSubmit={handleTransactionValidSubmit} ref={(e) => (setTransactionForm(e))}>
                        <Row>
                            <Col>
                                <AvField name="invoiceNr" label={intl.formatMessage({ id: 'INVOICE.FIELD.NUMBER' })} type="text" value={invoice && invoice.invoiceNr} readOnly={true} className="form-control-solid" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {transactionType == 2 && <AvField name="transactionAmount" label={intl.formatMessage({ id: 'INVOICE.MODAL.AMOUNT_RECEIVED' })} type="number" validate={validation} className='hide-arrow' />}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="mb-3">
                                    <label htmlFor="paymentDate" className="form-label">{intl.formatMessage({ id: 'INVOICE.MODAL.PAYMENT_DATE' })}</label>
                                    <Flatpickr ref={fppaymentdate}
                                        onChange={onPaymentDateChange}
                                        options={{
                                            dateFormat: "d-m-Y",
                                            maxDate: new Date()
                                        }}
                                        //value={paymentDate}
                                        className='form-control'
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end'>
                                <AvField type="hidden" name="id" value={invoice && invoice.id} />
                                <Button color="success" type="submit">{intl.formatMessage({ id: 'INVOICE.SAVE' })}</Button>
                            </Col>
                        </Row>
                    </AvForm>
                </Modal.Body>
                {loading && <Loader></Loader>}
            </Modal >
        )
    }

    return (

        <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({ id: 'INVOICES.BREADCRUMB.DESCRIPTION' })}</PageTitle>

            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-xxl">
                    <div className="d-flex flex-column gap-7 gap-lg-10">
                        <div className="d-flex flex-wrap flex-stack gap-5 gap-lg-10">
                            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto">
                                {
                                    tabs.map((tab, index) => (
                                        <PolicyChecker policies={tab.policies}>
                                            <li key={index} className="nav-item">
                                                <a className={`nav-link ${tab.active}`} data-bs-toggle="tab" href={tab.hash} role="tab" aria-selected="true">{tab.name}</a>
                                            </li>
                                        </PolicyChecker>
                                    ))
                                }

                            </ul>
                        </div>
                        <div className="tab-content">
                            <PolicyChecker policies={[enumPolicies.InvoiceRead, enumPolicies.InvoiceWrite, enumPolicies.InvoiceEdit]}>
                                {renderInvoice()}
                            </PolicyChecker>
                            <PolicyChecker policies={[enumPolicies.InvoiceWrite, enumPolicies.InvoiceEdit]}>
                                {renderSummary()}
                                {renderHistory()}
                            </PolicyChecker>
                        </div>
                    </div>
                </div>
            </div>
            {renderModal()}

            {loading && <Loader />}
        </>
    );
};

const mapStateToProps = state => {
    return {
        invoice: state.Invoice.invoice,
        historyList: state.Invoice.historyList,
        loading: state.Invoice.loading,
        historyLoading: state.Invoice.historyLoading,
        success: state.Invoice.success
    };
};

const mapActionsToProps = {
    clearState, getInvoice, patchInvoice, putItems, getHistory, postTransaction
}

export default connect(mapStateToProps, mapActionsToProps)(Invoice)